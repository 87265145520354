var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',{attrs:{"flat":"","rounded":"","outlined":_vm.$vuetify.theme.dark}},[_c('v-toolbar-title',{staticClass:"display-1 font-weight-medium"},[_vm._v("Messages")]),_c('v-spacer'),_c('v-toolbar-items',[_c('div',{staticClass:"pageHeaderListWrapper"},[_c('v-list-item',{staticClass:"pa-0",attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Unread Messages: "+_vm._s(_vm.unreadMessages ? _vm.unreadMessages.length : 0))])],1)],1)],1)])],1),(!_vm.$vuetify.theme.dark)?_c('v-divider'):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"border",attrs:{"dense":"","headers":[
        { text: 'Date Submitted', value: 'dateCreated' },
        { text: 'Email', value: 'email' },
        { text: 'Message', value: 'message' },
        { text: 'Replied to by Admin', value: 'repliedToByAdmin' } ],"items":_vm.unreadMessages,"items-per-page":-1,"item-class":_vm.itemClass},on:{"click:row":_vm.onViewMessages},scopedSlots:_vm._u([{key:"item.dateCreated",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.dateCreated))+" ")]}},{key:"item.message",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.truncateText(item.message, 100))+" ")]}},{key:"item.repliedToByAdmin",fn:function(ref){
      var item = ref.item;
return [(item.repliedToByAdmin)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-check-circle-outline")]):_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-close-circle-outline")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }