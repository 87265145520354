<template>
  <v-row>
    <!-- page header -->
    <v-col cols="12">
      <v-toolbar flat rounded :outlined="$vuetify.theme.dark">
        <v-toolbar-title class="display-1 font-weight-medium"
          >Messages</v-toolbar-title
        >
        <v-spacer />

        <v-toolbar-items>
          <div class="pageHeaderListWrapper">
            <v-list-item dense class="pa-0">
              <v-list-item-content>
                <v-list-item-title
                  >Unread Messages:
                  {{
                    unreadMessages ? unreadMessages.length : 0
                  }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-toolbar-items>
      </v-toolbar>
      <v-divider v-if="!$vuetify.theme.dark" />
    </v-col>
    <!-- page header -->

    <!-- unreadMessages table -->
    <v-col cols="12">
      <v-data-table
        dense
        :headers="[
          { text: 'Date Submitted', value: 'dateCreated' },
          { text: 'Email', value: 'email' },
          { text: 'Message', value: 'message' },
          { text: 'Replied to by Admin', value: 'repliedToByAdmin' },
        ]"
        :items="unreadMessages"
        :items-per-page="-1"
        class="border"
        :item-class="itemClass"
        @click:row="onViewMessages"
      >
        <template v-slot:[`item.dateCreated`]="{ item }">
          {{ formatDate(item.dateCreated) }}
        </template>
        <template v-slot:[`item.message`]="{ item }">
          {{ truncateText(item.message, 100) }}
        </template>
        <template v-slot:[`item.repliedToByAdmin`]="{ item }">
          <v-icon small color="success" v-if="item.repliedToByAdmin"
            >mdi-check-circle-outline</v-icon
          >
          <v-icon small color="error" v-else>mdi-close-circle-outline</v-icon>
        </template>
      </v-data-table>
    </v-col>
    <!-- unreadMessages table -->
  </v-row>
</template>

<script>
import { portalsApp } from "../../firebase";
export default {
  firestore: {
    userAccounts: portalsApp.firestore().collection("userAccounts"),
  },
  data: () => ({
    userAccounts: [],
  }),
  methods: {
    onViewMessages(item) {
      this.$router.push(`users/${item.userId}`);
    },
    itemClass(item) {
      if (item.repliedToByAdmin) return "green lighten-4";
      if (!item.repliedToByAdmin) return "red lighten-4";
      return "";
    },
  },
  computed: {
    unreadMessages() {
      if (!this.userAccounts) return [];
      let items = [];
      this.userAccounts.forEach((account) => {
        let unreadMessages = account.timeline.filter(
          (item) => !item.readByAdmin
        );
        if (unreadMessages.length) {
          unreadMessages.forEach((i) => {
            i.email = account.email;
            i.userId = account.id;
            items.push(i);
          });
        }
      });
      return items;
    },
  },
};
</script>

<style>
tr {
  cursor: pointer;
}
</style>